import {
  AD_METRICS_FILTER_BY_PROPERTIES,
  QUERY_OPTIONS,
} from "~/shared/config";
import { groupBy, round } from "~/shared/utils";
import { MetricsFilter, CustomMetricRule } from "~/types/Metrics";
import {
  FilledSubmissionFilter,
  GroupBy,
  Provider,
  Query,
  QueryOption,
  SubmissionFilter,
  LogicalOperator,
  Config,
} from "~/types/shared";

type QueryOptions = {
  value: keyof Query<any>;
  displayValue: string;
}[][];

const adMetricsFilterKeys = [
  "adAccountId",
  "adCampaignId",
  "adGroupId",
  "adGroupAdId",
  "adGroupAdProviderId",
  "adGroupAdPrimaryId",
  "adGroupAdCreativeId",
  "adAccountName",
  "adCampaignName",
  "adGroupName",
  "adGroupAdName",
  "provider",
  "creativeId",
  "description",
  "headline",
  "landingPage",
  "postId",
  "adFormat",
  "adGroupAdStatus",
  "adGroupAdTag",
  "adCampaignObjective",
  "primaryEmotion",
];

export const useFilter = () => {
  const getPropertyOptions = (
    provider: Provider,
    options?: Partial<{
      customConversionNames?: Record<string, string>;
      customMetricRules?: CustomMetricRule[];
      allowedProperties: Array<string> | null;
      disallowedProperties: Array<string> | null;
      showNotEnabled?: boolean;
    }>,
  ) => {
    const opts = options || {};
    const {
      providerToMetricConfig,
      customMetricRulesToCustomMetricNames,
      getScoreMetrics,
      getMiscMetrics,
    } = useMetrics();
    const { hasFeature } = useFeatureFlags();
    const metrics = providerToMetricConfig(provider);
    const scoreMetrics = getScoreMetrics();
    const miscMetrics = getMiscMetrics();
    const getConfig = opts.showNotEnabled
      ? <T>(config: T) => config
      : useGetEnabledConfig;
    const config = getConfig(AD_METRICS_FILTER_BY_PROPERTIES).filter((opt) =>
      typeof opt.featureFlag === "string" ? hasFeature(opt.featureFlag) : true,
    );

    const keepProperty = (prop: Config<string>) =>
      Array.isArray(opts.allowedProperties) &&
      Array.isArray(opts.disallowedProperties)
        ? opts.allowedProperties.includes(prop.id) &&
          !opts.disallowedProperties.includes(prop.id)
        : Array.isArray(opts.allowedProperties)
          ? opts.allowedProperties.includes(prop.id)
          : Array.isArray(opts.disallowedProperties)
            ? !opts.disallowedProperties.includes(prop.id)
            : true;

    const keepMetric = (metricName: string) =>
      Array.isArray(opts.allowedProperties) &&
      Array.isArray(opts.disallowedProperties)
        ? opts.allowedProperties.includes(metricName) &&
          !opts.disallowedProperties.includes(metricName)
        : Array.isArray(opts.allowedProperties)
          ? opts.allowedProperties.includes(metricName)
          : Array.isArray(opts.disallowedProperties)
            ? !opts.disallowedProperties.includes(metricName)
            : true;

    const baseOptions = config
      .filter((c) => keepProperty(c))
      .map((prop) => ({
        value: prop.id,
        displayValue: prop.displayName,
        group: prop.group,
        queryOptions: prop.queryOptions,
        component: prop.component,
        filterValueOptions: prop.filterValueOptions,
      }));

    const metricOptions = metrics
      .filter((c) => keepProperty(c))
      .map((metric) => ({
        value: metric.id,
        displayValue: metric.displayName,
        group: metric.group,
        queryOptions: ["lt", "gt", "lte", "gte", "eq", "neq"],
        component: "form-input" as "form-input" | "form-select" | "select-tag",
        filterValueOptions: [],
      }));

    const customConversionNames = opts.customConversionNames || {};
    const customConversionOptions = Object.entries(customConversionNames)
      .filter(([metric]) => keepMetric(metric))
      .map(([metric, displayName]) => ({
        value: metric,
        displayValue: displayName,
        group: "Custom Conversion",
        queryOptions: ["lt", "gt", "lte", "gte", "eq", "neq"],
        component: "form-input" as "form-input" | "form-select" | "select-tag",
        filterValueOptions: [],
      }));

    const customMetricRules = opts.customMetricRules || [];
    const customMetricNames =
      customMetricRulesToCustomMetricNames(customMetricRules);
    const customMetricOptions = Object.entries(customMetricNames)
      .filter(([metric]) => keepMetric(metric))
      .map(([metric, displayName]) => ({
        value: metric,
        displayValue: displayName,
        group: "3rd Party Metrics",
        queryOptions: ["lt", "gt", "lte", "gte", "eq", "neq"],
        component: "form-input" as "form-input" | "form-select" | "select-tag",
        filterValueOptions: [],
      }));

    const scoreMetricOptions = scoreMetrics
      .filter((c) => keepProperty(c))
      .map((metric) => ({
        value: metric.id,
        displayValue: metric.displayName,
        group: "Score Metrics",
        queryOptions: ["lt", "gt", "lte", "gte", "eq", "neq"],
        component: "form-input" as "form-input" | "form-select" | "select-tag",
        filterValueOptions: [],
      }));

    const miscMetricOptions = miscMetrics
      .filter((c) => keepProperty(c))
      .map((metric) => ({
        value: metric.id,
        displayValue: metric.displayName,
        group: "Other Metrics",
        queryOptions: ["lt", "gt", "lte", "gte", "eq", "neq"],
        component: "form-input" as "form-input" | "form-select" | "select-tag",
        filterValueOptions: [],
      }));

    const allOptions = [
      ...baseOptions,
      ...metricOptions,
      ...scoreMetricOptions,
      ...miscMetricOptions,
      ...customConversionOptions,
      ...customMetricOptions,
    ];
    return groupBy(allOptions, "group");
  };

  const getPropertyQueryOptions = (
    property: string | null,
    propertyOptions: ReturnType<typeof getPropertyOptions>,
  ) => {
    if (property == null) return [];
    const queryOptionIds = Object.keys(propertyOptions).reduce<string[]>(
      (acc, groupName) => {
        const option = propertyOptions[groupName].find(
          (option) => option.value === property,
        );

        if (option) {
          return option.queryOptions;
        }

        return acc;
      },
      [],
    );
    const queryOptions = [...useGetEnabledConfig(QUERY_OPTIONS)];
    return queryOptions
      .filter((option) => queryOptionIds.includes(option.id))
      .map((o) => ({
        value: o.id,
        displayValue: o.displayName,
      }));
  };

  const getPropertyFilterValueOptions = (dto: {
    property: string | null;
    propertyOptions: ReturnType<typeof getPropertyOptions>;
    provider: Provider;
  }): Array<{
    value: any;
    displayValue: string;
    color: string;
  }> => {
    const { property, propertyOptions } = dto;
    if (property == null) {
      return [];
    }
    if (property === "adGroupAdTag") {
      return [];
    }
    return Object.keys(propertyOptions).reduce<
      Array<{
        value: any;
        displayValue: string;
        color: string;
      }>
    >((acc, groupName) => {
      const option = propertyOptions[groupName].find(
        (option) => option.value === property,
      );

      if (option) {
        return option.filterValueOptions.filter((option) =>
          option.providers.includes(dto.provider),
        );
      }

      return acc;
    }, []);
  };

  const getDisplayFilter = (dto: {
    filter: MetricsFilter;
    idx: number;
    queryOptions: QueryOptions;
    propertyOptions: ReturnType<typeof getPropertyOptions>;
    formatValueDto?: {
      provider: Provider;
      currency: string;
      customMetricRules: CustomMetricRule[];
    };
  }) => {
    const sFilter = metricsFilterToSubmissionFilter(
      [[dto.filter]],
      dto.formatValueDto,
    )[0];
    return getDisplaySubmissionFilter({
      filter: sFilter,
      idx: dto.idx,
      queryOptions: dto.queryOptions,
      propertyOptions: dto.propertyOptions,
    });
  };

  const getDisplaySubmissionFilter = (dto: {
    filter: SubmissionFilter;
    idx: number;
    queryOptions: QueryOptions;
    propertyOptions: ReturnType<typeof getPropertyOptions>;
  }) => {
    const { filter, idx, queryOptions, propertyOptions } = dto;
    if (filter.property == null || filter.queryOption == null)
      return { property: "", queryOption: "", value: "" };
    const propertyOption = Object.keys(propertyOptions).reduce<{
      displayValue: string;
      filterValueOptions: Array<{
        value: any;
        displayValue: string;
        color: string;
      }>;
    } | null>((acc, groupName) => {
      const option = propertyOptions[groupName].find(
        (option) => option.value === filter.property,
      );
      if (option) {
        return option;
      }
      return acc;
    }, null);
    let property = propertyOption?.displayValue;
    if (propertyOption == null && filter.property === "adGroupAdPrimaryId") {
      property = "Ad ID";
    }
    let queryOption = queryOptions[idx].find(
      (option) => option.value === filter.queryOption,
    )?.displayValue;
    if (queryOption == null && filter.property === "adGroupAdPrimaryId") {
      queryOption = "equals";
    }
    const optionValue = propertyOption?.filterValueOptions.find(
      (option) => option.value === filter.value,
    );
    if (optionValue) {
      return {
        property: property ?? "",
        queryOption: queryOption ?? "",
        value: optionValue.displayValue,
      };
    }
    const valueTooLong =
      typeof filter.value === "string" ? filter.value.length > 97 : false;
    let value = valueTooLong ? filter.value.slice(0, 97) + "..." : filter.value;
    if (filter.property === "adGroupAdTag") {
      const tagId = Number(filter.value);
      const taggingStore = useTaggingStore();
      const tag = taggingStore.tags.find((tag) => tag.id === tagId);
      value = tag ? tag.name : "";
    }
    return {
      property: property ?? "",
      queryOption: queryOption ?? "",
      value,
    };
  };

  const submissionFilterToMetricsFilter = (
    sFilters: Array<SubmissionFilter>,
    formatValueDto: {
      provider: Provider;
      currency: string;
      customMetricRules: CustomMetricRule[];
    } | null = null,
  ) => {
    const andFilters: MetricsFilter[][] = [];
    const orFilter: MetricsFilter[] = [];
    sFilters.forEach((filter) => {
      if (
        filter.property == null ||
        filter.queryOption == null ||
        filter.value == null
      ) {
        return;
      }
      if (filter.logicalOperator === "AND" && orFilter.length > 0) {
        andFilters.push([...orFilter]);
        orFilter.splice(0, orFilter.length);
      }
      const value = getMetricsFilterValue(
        filter as FilledSubmissionFilter,
        formatValueDto,
      );
      orFilter.push({ ...value });
    });
    if (orFilter.length > 0) {
      andFilters.push([...orFilter]);
    }
    return andFilters;
  };

  const getMetricsFilterValue = (
    filter: FilledSubmissionFilter,
    formatValueDto: {
      provider: Provider;
      currency: string;
      customMetricRules: CustomMetricRule[];
    } | null = null,
  ): MetricsFilter => {
    const value = mapMetricValue(filter.property, filter.value, formatValueDto);
    const mapped: MetricsFilter = {};
    mapped[filter.property] = {
      [filter.queryOption as QueryOption]: value,
    };
    return mapped;
  };

  const mapMetricValue = (
    property: string,
    value: number | string | undefined | null,
    formatValueDto: {
      provider: Provider;
      customMetricRules: CustomMetricRule[];
    } | null = null,
  ) => {
    value = value == null ? "" : value.toString();
    const { getValueFormatterType } = useMetrics();
    if (isMetricsFilter(property) && formatValueDto) {
      const { provider, customMetricRules } = formatValueDto;
      const valueFormatter = getValueFormatterType(
        property,
        provider,
        customMetricRules,
      );
      const replaceComma =
        valueFormatter === "percentage" ||
        valueFormatter === "decimalPercentage" ||
        valueFormatter === "decimalAsPercentage";
      if (replaceComma) {
        // Replace comma for percentages as they will be removed in the next step otherwise
        value = value.replace(",", ".");
      }
      const num = convertFormattedNumberToNumber(value);
      // Divide by 100 if the value is a percentage as we store them as decimals
      const divideBy100 =
        valueFormatter === "percentage" ||
        valueFormatter === "decimalPercentage";
      return toDecimal(num, divideBy100);
    }
    return value;
  };

  const convertFormattedNumberToNumber = (formattedNumber: string): number => {
    // Remove all non-numeric characters except for the decimal point and negative sign
    const cleanedString = formattedNumber.replace(/[^0-9.-]+/g, "");

    // Parse the cleaned string to a number
    const numericValue = parseFloat(cleanedString);

    // Return the numeric value
    return numericValue;
  };

  const toDecimal = (num: number, divideBy100: boolean) => {
    num = divideBy100 ? num / 100 : num;
    return round(num, 4).toString();
  };

  const metricsFilterToSubmissionFilter = (
    andFilters: MetricsFilter[][],
    formatValueDto: {
      provider: Provider;
      currency: string;
      customMetricRules: CustomMetricRule[];
    } | null = null,
    firstOperator: LogicalOperator = "OR",
  ) => {
    if (andFilters.length <= 0) {
      return [{ ...emptySubmissionFilter }];
    }
    const allInnerFilterEmpty = andFilters.every(
      (orFilter) => orFilter.length === 0,
    );
    if (allInnerFilterEmpty) {
      return [{ ...emptySubmissionFilter }];
    }
    const { getValueFormatter } = useMetrics();
    const sFilters: Array<SubmissionFilter> = [];
    andFilters.forEach((orFilter, andFilterIdx) => {
      orFilter.forEach((filter, orFilterIdx) => {
        const property = Object.keys(filter)[0];
        const query = filter[property];
        if (query == null) return;
        const queryOption = Object.keys(query)[0] as QueryOption;
        const value = query[queryOption];
        if (value == null) return;
        const valueFormatter = formatValueDto
          ? getValueFormatter(
              property,
              formatValueDto.provider,
              formatValueDto.customMetricRules,
              { currency: formatValueDto.currency, compact: false },
            )
          : null;
        sFilters.push({
          // The first in the inner array is connected by and with the previous one
          logicalOperator:
            orFilterIdx === 0 && andFilterIdx !== 0 ? "AND" : firstOperator,
          property,
          queryOption,
          value:
            valueFormatter && isMetricsFilter(property)
              ? valueFormatter(value)
              : value.toString(),
        });
      });
    });
    return sFilters;
  };

  const isMetricsFilter = (property: string) => {
    return !isMetadataFilter(property);
  };

  const isMetadataFilter = (property: string) => {
    return adMetricsFilterKeys.includes(property);
  };

  const getGroupByFilter = (breakdown: string | number, groupBy: GroupBy) => {
    const idFilters: Array<MetricsFilter> = [];

    switch (groupBy) {
      case GroupBy.ADNAME:
        idFilters.push({ adGroupAdName: { eq: breakdown.toString() } });
        break;

      case GroupBy.CREATIVE_ID:
        idFilters.push({ creativeId: { eq: breakdown.toString() } });
        break;

      case GroupBy.DESCRIPTION:
        idFilters.push({ description: { eq: breakdown.toString() } });
        break;

      case GroupBy.HEADLINE:
        idFilters.push({ headline: { eq: breakdown.toString() } });
        break;

      case GroupBy.LANDING_PAGE:
        idFilters.push({ landingPage: { eq: breakdown.toString() } });
        break;

      case GroupBy.POST_ID:
        idFilters.push({ postId: { eq: breakdown.toString() } });
        break;

      case GroupBy.AD_FORMAT:
        idFilters.push({ adFormat: { eq: breakdown.toString() } });
        break;

      case GroupBy.AD_GROUP_ID:
        idFilters.push({ adGroupId: { eq: breakdown.toString() } });
        break;

      case GroupBy.AD_CAMPAIGN_ID:
        idFilters.push({ adCampaignId: { eq: breakdown.toString() } });
        break;

      case GroupBy.PRIMARY_EMOTION:
        idFilters.push({ primaryEmotion: { eq: breakdown.toString() } });
        break;

      default:
        idFilters.push({ adGroupAdId: { eq: breakdown.toString() } });
        break;
    }

    return idFilters;
  };

  const emptySubmissionFilter: SubmissionFilter = {
    logicalOperator: "OR",
    property: null,
    queryOption: null,
    value: "",
  };

  const emptyAdNameContainsSubmissionFilter: SubmissionFilter = {
    logicalOperator: "OR",
    property: "adGroupAdName",
    queryOption: "contains",
    value: "",
  };

  const isFilterPassed = (
    queryOption: QueryOption | null,
    filterByValue: number,
    actualValue: number,
  ) => {
    actualValue = round(actualValue);
    filterByValue = round(filterByValue);

    if (queryOption === "eq") {
      return actualValue === filterByValue;
    }

    if (queryOption === "neq") {
      return actualValue !== filterByValue;
    }

    if (queryOption === "gt") {
      return actualValue > filterByValue;
    }

    if (queryOption === "gte") {
      return actualValue >= filterByValue;
    }

    if (queryOption === "lt") {
      return actualValue < filterByValue;
    }

    if (queryOption === "lte") {
      return actualValue <= filterByValue;
    }

    return false;
  };

  const removeFiltersWithCustomMetric = <
    T extends MetricsFilter | MetricsFilter,
  >(dto: {
    metricName: string;
    filter: T[][];
  }) => {
    return dto.filter.map((filter) =>
      filter.filter((f) => !(dto.metricName in f)),
    );
  };

  const renameFiltersWithCustomMetric = <
    T extends MetricsFilter | MetricsFilter,
  >(dto: {
    oldMetricName: string;
    newMetricName: string;
    filter: T[][];
  }) => {
    return dto.filter.map((filter) =>
      filter.map((f) => {
        if (dto.oldMetricName in f) {
          return {
            [dto.newMetricName]: f[dto.oldMetricName],
          };
        }
        return f;
      }),
    );
  };

  const removeMetricsFilter = (
    filters: Array<Array<MetricsFilter>> | undefined | null,
  ) => {
    if (!Array.isArray(filters)) {
      return [];
    }

    const result: Array<Array<MetricsFilter>> = [];

    filters.forEach((andFilter) => {
      const orFilters = andFilter.filter((filter) =>
        Object.keys(filter).every((property) => isMetadataFilter(property)),
      );

      if (orFilters.length > 0) {
        result.push(orFilters);
      }
    });

    return result;
  };

  const breakdownToGroupByFilter = (
    breakdown: string | number,
    groupBy: GroupBy,
  ) => {
    const idFilters: Array<MetricsFilter> = [];

    switch (groupBy) {
      case GroupBy.ADNAME:
        idFilters.push({ adGroupAdName: { eq: breakdown.toString() } });
        break;

      case GroupBy.CREATIVE_ID:
        idFilters.push({ creativeId: { eq: breakdown.toString() } });
        break;

      case GroupBy.DESCRIPTION:
        idFilters.push({ description: { eq: breakdown.toString() } });
        break;

      case GroupBy.HEADLINE:
        idFilters.push({ headline: { eq: breakdown.toString() } });
        break;

      case GroupBy.LANDING_PAGE:
        idFilters.push({ landingPage: { eq: breakdown.toString() } });
        break;

      case GroupBy.POST_ID:
        idFilters.push({ postId: { eq: breakdown.toString() } });
        break;

      case GroupBy.AD_FORMAT:
        idFilters.push({ adFormat: { eq: breakdown.toString() } });
        break;

      case GroupBy.PRIMARY_EMOTION:
        idFilters.push({ primaryEmotion: { eq: breakdown.toString() } });
        break;

      default:
        idFilters.push({ adGroupAdPrimaryId: { eq: Number(breakdown) } });
        break;
    }

    return idFilters;
  };

  return {
    getDisplayFilter,
    getDisplaySubmissionFilter,
    submissionFilterToMetricsFilter,
    metricsFilterToSubmissionFilter,
    getPropertyOptions,
    getPropertyQueryOptions,
    getGroupByFilter,
    emptySubmissionFilter,
    emptyAdNameContainsSubmissionFilter,
    mapMetricValue,
    getPropertyFilterValueOptions,
    isFilterPassed,
    removeFiltersWithCustomMetric,
    renameFiltersWithCustomMetric,
    removeMetricsFilter,
    breakdownToGroupByFilter,
  };
};
